import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, Inject} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { OwnerOperatorHeaderService } from '../../../shared/services/owner-operator-header.service';
import { OwnerOperatorPermissionsService } from '../../../shared/services/owner-operator-permissions.service';
import { OwnerOperatorHomepageService } from '../../../shared/services/owner-operator-homepage.service';
import { Router, NavigationStart } from '@angular/router';
import { OwnerOperatorChangeMarketLanguageService } from '../../../shared/services/owner-operator-change-market-language.service';
import { AuthUserService } from '../../../services/auth-user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'angular-2-local-storage';
import * as _ from 'lodash';
import { OOP_URL_CONST } from '../../../shared/constants/rfmOOPUrl.constant';
import { OOP_CONST } from '../../../shared/constants/rfmOOP.constant';
import { AdobeAnalyticsService } from '../../../services/adobe-analytics.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-owner-operator-menu',
  templateUrl: './owner-operator-menu.component.html',
  styleUrls: ['./owner-operator-menu.component.scss']
})
export class OwnerOperatorMenuComponent implements OnInit {

    hamburgerMenuData: any[];
    navbarStatus: boolean;
    actionItemMenuPermission: boolean = false;
    actionItemMenuPTAPermission: any;
    actionItemMenuMenuInactivePermissionPermission: any;
    newLegacyPageFlag: boolean;
    showToAdmin: boolean;
    disableMarketDropdown: boolean = false;
    disableLangDropdown: boolean = false;
    userMarketList: any[];
    selectedMarket: string;
    userLanguageList = [];
    selectedLanguage: any = {
        "selectedMarket": "",
        "selectedLanguageId": "",
        "selectedLanguage": "",
        "selectedLanguageDesc": "",
        "languageName": ""
    };
    localeLangID: any;
    userDetails: any;
    errorMsg: string;
    menuMessageMap: any;
    actionItemMenuLink: string;
    public mark_lang: any;
    public pathName: any;
    HomeLink : string;
    ownerOperatorPtaView : string;
    selectedDateFromat : any;
    myPath: string
    legacyModalReference: NgbModalRef;

    @Input() getnavbarStatus: boolean;
    @Input() setnewLegacyFlag: boolean;
    @Input() setKeyBoardToggle: any;
    @Output() getKeyBoardToggle = new EventEmitter();
    @Output() getNavbarStatusChang = new EventEmitter();
    @Output() redirectToLegacyClicked = new EventEmitter();

    @ViewChild('homeLink') private navBarRef: ElementRef<HTMLElement>;
    constructor(

        public OwnerOperatorHeaderService: OwnerOperatorHeaderService, 
        private http: HttpClient,
        @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
        public ownerOperatorPermissionsService: OwnerOperatorPermissionsService,
        public ownerOperatorHomepageService: OwnerOperatorHomepageService, 
        private router: Router,
        private OwnerOperatorChangeMarketLanguageService: OwnerOperatorChangeMarketLanguageService,
        private authUserService: AuthUserService,
        private spinnerService: NgxSpinnerService,
        private localStorageService: LocalStorageService,
        private adobeService: AdobeAnalyticsService,
        private modalService: NgbModal
    ) {}

    setNavbarStatus() {
        if (this.getnavbarStatus)
        this.navbarStatus = false;
        else if (!this.getnavbarStatus)
        this.navbarStatus = true;
        this.getNavbarStatusChang.emit(this.navbarStatus);

    }

    navigateToQuickLink(tileQuickLink) {
        if (!this.router.url.startsWith(tileQuickLink)) {
        this.sessionStorage.remove("reloadPage");
        if(tileQuickLink.toLowerCase().includes("category")) this.router.navigate(["rfm-category"],{queryParams: sessionStorage});
        this.router.navigate([tileQuickLink]);
        }
    }

    ngOnInit(): void {
        this.myPath=window.location.pathname;
        this.HomeLink = OOP_URL_CONST.OWNER_OPERATOR_HOMEPAGE;
        this.ownerOperatorPtaView = OOP_URL_CONST.ACTION_ITEM_OWNER_OPERATOR_VIEW_PTA_VIEW_PAGE;
        this.menuMessageMap = this.sessionStorage.get(OOP_CONST.MESSAGEMAP);
    //  this.showToAdmin = this.setnewLegacyFlag;//this.OwnerOperatorHeaderService.validateLegacyRFMPage();

    this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
        this.pathName = event.url;
        }
        else {
        this.pathName = location.pathname;
        }
        if (location.pathname == OOP_URL_CONST.PRICE_TAX_ACTIVATE_EFFECTIVEDATE_PAGE || location.pathname == OOP_URL_CONST.MASS_MENU_ITEM_INACTIVATION_EFFECTIVE_DATE) {
        let path = this.pathName.split(OOP_CONST.SYMBOL_SINGLE_SLASH);
        this.pathName = OOP_CONST.SYMBOL_SINGLE_SLASH + path[1];
        }
    });
    if (this.pathName == undefined) {
        this.pathName = location.pathname;
        if (location.pathname == OOP_URL_CONST.PRICE_TAX_ACTIVATE_EFFECTIVEDATE_PAGE || location.pathname == OOP_URL_CONST.MASS_MENU_ITEM_INACTIVATION_EFFECTIVE_DATE) {
        let path = this.pathName.split(OOP_CONST.SYMBOL_SINGLE_SLASH);
        this.pathName = OOP_CONST.SYMBOL_SINGLE_SLASH + path[1];
        }
    }

    this.loadMenuData(); 

        this.actionItemMenuPTAPermission = this.ownerOperatorPermissionsService.ownerOperatorPermissions(OOP_CONST.PERMISSION_OWNER_OPERATOR_PRICE_TAX_ACTIVATE);
        this.actionItemMenuMenuInactivePermissionPermission = this.ownerOperatorPermissionsService.ownerOperatorPermissions(OOP_CONST.PERMISSION_OWNER_OPERATOR_MENU_ITEM_INACTIVATION);

        if (this.actionItemMenuMenuInactivePermissionPermission != 4 || this.actionItemMenuPTAPermission != 4) {
        this.actionItemMenuPermission = true;
        if (this.actionItemMenuPTAPermission != 4 ) {
            this.actionItemMenuLink= OOP_URL_CONST.ACTION_ITEM_OWNER_OPERATOR_VIEW_PTA_VIEW_PAGE;
        }
        else if (this.actionItemMenuPTAPermission == 4 && this.actionItemMenuMenuInactivePermissionPermission != 4) {
            this.actionItemMenuLink=OOP_URL_CONST.ACTION_ITEM_OWNER_OPERATOR_VIEW_MII_VIEW_PAGE;
        }
        } 
        
        this.userDetails = this.sessionStorage.get(OOP_CONST.USER_DETAILS);
        this.selectedMarket = this.sessionStorage.get(OOP_CONST.MARKET_ID);
        this.localeLangID = this.sessionStorage.get(OOP_CONST.LOCALE_LANG_ID);
        this.userMarketList = this.userDetails[OOP_CONST.LANGUAGE_MAP][OOP_CONST.REFRESH_USER_CONTEXT][OOP_CONST.USER_MARKET_LIST];
        this.mark_lang = this.selectedMarket+OOP_CONST.SYMBOL_UNDERSCORE+this.localeLangID;
        this.selectedDateFromat = this.sessionStorage.get("selectedDateFromat");

        for (let i of this.userMarketList) {
        for (let j of i) {
            if (j == OOP_CONST.NO_MARKET) {
            this.userMarketList.splice(this.userMarketList.findIndex(function (i) {
                return i[0] == OOP_CONST.ZERO;
            }), 1);
            }
        }
        }
        
        let userLanguages = this.userDetails[OOP_CONST.LANGUAGE_MAP][OOP_CONST.REFRESH_USER_CONTEXT][OOP_CONST.MARKET_LANGUAGE_MAP][this.mark_lang];
        let lang_keys = Object.keys(userLanguages);
        for (let i = 0; i < lang_keys.length; i++) {
        let langKey = lang_keys[i];
        let localeLanguageDesc = this.userDetails[OOP_CONST.LANGUAGE_MAP][OOP_CONST.REFRESH_USER_CONTEXT][OOP_CONST.MARKET_LANGUAGE_MAP][this.mark_lang][langKey].localeLanguageDesc;
        let languageId = this.userDetails[OOP_CONST.LANGUAGE_MAP][OOP_CONST.REFRESH_USER_CONTEXT][OOP_CONST.MARKET_LANGUAGE_MAP][this.mark_lang][langKey].localeLanguage;
        let languageName = this.userDetails[OOP_CONST.LANGUAGE_MAP][OOP_CONST.REFRESH_USER_CONTEXT][OOP_CONST.MARKET_LANGUAGE_MAP][this.mark_lang][langKey].languageName;
        this.userLanguageList.push({ "langKey": lang_keys[i], "localeLanguageDesc": localeLanguageDesc, "languageId": languageId, "languageName" : languageName})
        this.userLanguageList = _.sortBy(this.userLanguageList, OOP_CONST.LOCALE_LANG_DESC);
        }
        
        
        this.disableDropdown();
        this.getKeyBoardToggle.emit(false);
    }

    ngOnChanges() {
        
        window.setInterval(() => {
        this.myPath=window.location.pathname;
        }, 1000);
        this.loadMenuData();
        if(this.setKeyBoardToggle === "keyboard"){
            this.navBarRef.nativeElement.focus();
        } 
         
    }

    public loadMenuData() {
        this.ownerOperatorHomepageService.getHomepageData((data) => {
            this.hamburgerMenuData = data;//this.OwnerOperatorHeaderService.validatePermission(data);
            for (var i = 0; i < this.hamburgerMenuData.length; i++) {
                let base_pathName = this.pathName.split('/')[1];
                let base_tileQuickLink = this.hamburgerMenuData[i]['tileQuickLink'].split('/')[1];
                if (base_tileQuickLink == 'message-management') {
                    if (this.hamburgerMenuData[i]['tileQuickLink'] == '/message-management/messages') {
                        if (this.pathName == this.hamburgerMenuData[i]['tileQuickLink']) {
                            this.hamburgerMenuData[i]['selectedMenu'] = true;
                        } else {
                            this.hamburgerMenuData[i]['selectedMenu'] = false;
                        }
                    } else {
                        if (this.pathName != '/message-management/messages')
                            if (base_pathName == base_tileQuickLink)
                                this.hamburgerMenuData[i]['selectedMenu'] = true;
                            else
                                this.hamburgerMenuData[i]['selectedMenu'] = false;
                        else
                            this.hamburgerMenuData[i]['selectedMenu'] = false;
                    }
                } else {
                    if (base_pathName == base_tileQuickLink)
                        this.hamburgerMenuData[i]['selectedMenu'] = true;
                    else
                        this.hamburgerMenuData[i]['selectedMenu'] = false;
                }
            }
        });
    }

    public disableDropdown() {
        this.router.events.subscribe(val => {
        if (location.pathname != this.HomeLink) {
            this.disableMarketDropdown = this.disableLangDropdown = true;
        }
        else if (location.pathname == this.HomeLink) {
            
            if (this.userMarketList.length > 1) {
            this.disableMarketDropdown = false;
            }
            this.disableLangDropdown = false;
        }
        });
        if (location.pathname != this.HomeLink) {
        this.disableMarketDropdown = this.disableLangDropdown = true;
        }
        if (location.pathname == this.HomeLink && this.userMarketList.length == 1) {
            this.disableMarketDropdown = true;
        }
    }

    public changeLanguage(event) {
        this.setNavbarStatus();
        this.spinnerService.show();
        this.selectedLanguage.selectedMarket = this.sessionStorage.get(OOP_CONST.MARKET_ID);
        this.selectedLanguage.selectedLanguageId = event.target.value;
        this.selectedLanguage.selectedLanguage = this.userLanguageList.find(x => x.languageId == event.target.value).langKey;
        this.selectedLanguage.selectedLanguageDesc = this.userLanguageList.find(x => x.languageId == event.target.value).localeLanguageDesc;
        this.selectedLanguage.languageName = this.userLanguageList.find(x => x.languageId == event.target.value).languageName;

        this.OnChangeSetMessageMap(this.selectedLanguage);
    }
    navigateToHome(){
    if(this.pathName != this.HomeLink)
    window.location.href=this.HomeLink;
    }

    public changeMarket(event){
        this.selectedLanguage.selectedMarket = event.target.value;
        let userLanguages = this.userDetails[OOP_CONST.LANGUAGE_MAP][OOP_CONST.REFRESH_USER_CONTEXT][OOP_CONST.MARKET_LANGUAGE_MAP][this.selectedLanguage.selectedMarket];
        let lang_key= Object.keys(userLanguages);
        for(let i = 0; i< lang_key.length; i++)
        {
            let val=lang_key[i];
            if(userLanguages[val][OOP_CONST.DEFAULT] == true){
            this.selectedLanguage.selectedLanguageId = userLanguages[val][OOP_CONST.LOCALE_LANG];
            this.selectedLanguage.selectedLanguage = val;
            this.selectedLanguage.selectedLanguageDesc = userLanguages[val][OOP_CONST.LOCALE_LANG_DESC];
            this.selectedLanguage.languageName = userLanguages[val][OOP_CONST.LOCALE_LANG_NAME];
            }
        }
        this.OnChangeSetMessageMap(this.selectedLanguage);
    }

    public OnChangeSetMessageMap(selectedLanguage){
        this.OwnerOperatorChangeMarketLanguageService.changeLanguageMarket(OOP_CONST.LOAD_MESSAGE_MAP, selectedLanguage).subscribe((data) => {
        this.sessionStorage.remove(OOP_CONST.MARKET_ID);
        this.sessionStorage.set(OOP_CONST.MARKET_ID, selectedLanguage.selectedMarket);
        this.sessionStorage.remove(OOP_CONST.MESSAGEMAP);
        this.sessionStorage.set(OOP_CONST.MESSAGEMAP, data.messageMap);
        this.sessionStorage.remove(OOP_CONST.LOCALE_LANG_ID);
        this.sessionStorage.set(OOP_CONST.LOCALE_LANG_ID, selectedLanguage.selectedLanguageId);
        this.sessionStorage.remove(OOP_CONST.LOCALE_LANG_DESC);
        this.sessionStorage.set(OOP_CONST.LOCALE_LANG_DESC, selectedLanguage.languageName);
        this.sessionStorage.remove(OOP_CONST.SELECTED_LANGUAGE);
        this.sessionStorage.set(OOP_CONST.SELECTED_LANGUAGE, selectedLanguage.selectedLanguage);
        this.sessionStorage.remove(OOP_CONST.SELECTED_DATE_FORMAT);
        this.sessionStorage.set(OOP_CONST.SELECTED_DATE_FORMAT, data.selectedDateFromat.toUpperCase());
        this.sessionStorage.set('auditLogDateFormat', data.selectedDateFromat); 
        
        this.authUserService.getAuthUserDetails(OOP_CONST.LOGIN).subscribe((users) => {
            this.userDetails = users;
            this.sessionStorage.set(OOP_CONST.LOGIN_USER_STATUS, OOP_CONST.TRUE);
            this.sessionStorage.set(OOP_CONST.USER_DETAILS, users);
            this.sessionStorage.set(OOP_CONST.PERMISSION_MAP, users.permissionMap);
            this.sessionStorage.set(OOP_CONST.MESSAGEMAP, users.messageMap);
            this.localStorageService.set(OOP_CONST.MESSAGEMAP, users.messageMap);

            if (this.sessionStorage.get(OOP_CONST.IS_REST) == OOP_CONST.R) {
            this.sessionStorage.set(OOP_CONST.REST_NODE_LIST, users.restNodeList);
            }
            window.location.href = this.sessionStorage.get(OOP_CONST.RFM_REFRESH_PAGE);
        }, err => {
            this.errorMsg = <any>err;
            //this.spinnerService.hide();
        });
        });
    }
    trackLegacy(legacyModal){
        this.adobeService.trackLegacyRFM();
        this.legacyModalReference = this.modalService.open(legacyModal);
    }

    navigateToLegacyPage(event,pagnename){
        this.adobeService.navigateToLegacy(event , pagnename);
        this.legacyModalReference.close();
    }

    redirectToLegacyMethod(){
        this.redirectToLegacyClicked.emit('');
        this.navigateToLegacyPage('Ok', 'Owner-Operator-Homepage');
    }
      
    closeModal(){
        this.navigateToLegacyPage('Close', 'Owner-Operator-Homepage');
    }

    menuLinkClick(menu){
        this.setNavbarStatus();
        this.navigateToQuickLink(menu.tileQuickLink!=''? menu.tileQuickLink : menu.actionItemLink);
        this.samePageClick(menu.tileQuickLink!=''? menu.tileQuickLink : menu.actionItemLink);
    }

    homeOrActionLinkClick(pageLink){
        this.setNavbarStatus();
        this.samePageClick(pageLink);
    }

    samePageClick(link: string){
        if (this.router.url.startsWith(link)) {
            this.getKeyBoardToggle.emit(true);
        }
    }
}
